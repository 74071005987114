import models from './models';

export default {
  appName: process.env.VUE_APP_NAME || 'Doclivi Admin',
  appKey: 'doclivi_admin',
  appLogo: '',
  display: {
    primaryColor: '#66dac7',
    backgroundColor: 'primary',
    backgroundImage: 'https://source.unsplash.com/random/1920x1080',
    appLogo: '',
  },
  titleLogo: '',
  env: process.env.VUE_APP_ENV || 'development', // production / test
  defaultLocale: 'fr',
  /* eslint-disable */
  apiUrl: process.env.VUE_APP_API_URL || '/',
  buildDate: process.env.BUILDDATE || 'now',
  version: '',
  defaultTitle: process.env.VUE_APP_NAME || 'Doclivi Admin',
  primaryKey: 'id', // || '_id'
  features: {
    googleAuth: false,
    facebookAuth: false,
    register: false,
    passwordReset: true,
    autoWireAllModels: true,
  },
  automaticModels: models,
};
